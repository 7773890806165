import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Collapse } from 'react-collapse';
import FormControl from 'src/components/base/formControl';
import FileInput from 'src/components/base/fileInput';
import SubmitButton from 'src/components/base/submitButton';
import { useGlobalState } from 'src/components/base/globalState';
import MarketingOptinInput from 'src/components/base/marketingOptinInput';
import StructuredText from 'src/components/base/structuredText';

import {
  REFERRERS,
  getScheduleOptions,
  formatLocalTime,
  submitForm,
  useHasMounted,
} from 'src/util';
import * as classes from './blockScheduleACallForm.module.scss';

const { GATSBY_TIME_SLOTS_URL } = process.env;

const VISIT_DURATION = 30;

const BlockScheduleACallForm = ({
  preformContent,
  demoHeader,
  insuranceHeader,
  insurancePreformContent,
  insuranceYesOption,
  insuranceYesDetails,
  insuranceNoOption,
  insuranceNoDetails,
  scheduleHeader,
  schedulePreformContent,
  otherHeader,
  buttonText,
  onComplete,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [pronouns, setPronouns] = useState();
  const [birthdate, setBirthdate] = useState();
  const [referrer, setReferrer] = useState();
  const [referrerOther, setReferrerOther] = useState(); // text in 'Please describe...'
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [visitTime, setVisitTime] = useState();
  const [insurance, setInsurance] = useState();
  const [files, setFiles] = useState([]);
  const [other, setOther] = useState();
  const [scheduleOptions, setScheduleOptions] = useState([]);

  const { state, paymentType, payor, patientReferrer, referralCode } = useGlobalState();

  // Oregon and Washington patients can schedule calls for ALL available boulder business hours.
  // Patients from other states will not be able to schedule calls to occur between the hours of 11am and 1pm PST.
  // This business rule is confined to the start.boulder.care site, but if we ever need to extend it to another
  // app, then it should be moved into the boulder-server code.
  const excludeHours = state === 'Oregon' || state === 'Washington' ? [] : [11, 12];

  // If the user hasn't gone through the enrollment flow yet, redirect them to do so.
  // Override if patient has been referred
  useEffect(() => {
    // default referrer to selection from eligibility form
    if (patientReferrer) {
      setReferrer(patientReferrer.referrerSelection);
    }
  }, []);

  // Schedule options are time dependent, so we initially render an empty list on the server, and then fill it
  // in with valid options on the client -- otherwise we'd have hydration issues since the server-rendered
  // page wouldn't match the client. See https://joshwcomeau.com/react/the-perils-of-rehydration/
  const hasMounted = useHasMounted();

  useEffect(() => {
    async function loadTimeSlots() {
      try {
        const {
          data: { timeslots: availableSlots = [] },
        } = await axios.get(GATSBY_TIME_SLOTS_URL, { params: {} });
        setScheduleOptions(getScheduleOptions(availableSlots, excludeHours));
      } catch (e) {
        console.error(e);
      }
    }
    if (hasMounted) {
      // Load the initial set of time slots that has all available slots listed so that there are
      // options available to the user right away.
      setScheduleOptions(getScheduleOptions([], excludeHours));
      loadTimeSlots();
    }
    // If a potential participant is paying out of pocket we set the insurance to no
    // and hide the insurance section
    if (paymentType === 'self-pay') {
      setInsurance('no');
    }
  }, [hasMounted, paymentType]);

  return (
    <div>
      <form
        id="enroll"
        action={process.env.GATSBY_FORM_HANDLER}
        onSubmit={(evt) => {
          setSubmitting(true);
          submitForm(
            evt,
            files,
            () => onComplete({ phone, date: formatLocalTime(visitTime) }),
            () => setSubmitting(false),
          );
        }}
      >
        <input type="hidden" name="id" value="onboarding" />
        <input type="hidden" name="requestType" value="Enrollment" />
        <input type="hidden" name="duration" value={VISIT_DURATION} />
        <input type="hidden" name="referralCode" value={referralCode ?? ''} />

        <input type="hidden" name="state" value={state ?? ''} />
        <input type="hidden" name="paymentType" value={payor?.originalId ?? ''} />
        <input type="hidden" name="payorName" value={payor?.name ?? ''} />
        <input type="hidden" name="paymentMethod" value={paymentType ?? ''} />

        <StructuredText data={preformContent} />

        <h2>{demoHeader}</h2>
        <FormControl
          name="firstName"
          label="First name"
          value={firstName}
          setValue={setFirstName}
          required
        />
        <FormControl
          name="lastName"
          label="Last name"
          value={lastName}
          setValue={setLastName}
          required
        />
        <FormControl
          name="pronouns"
          label="Pronouns (optional)"
          value={pronouns}
          setValue={setPronouns}
        />
        <FormControl
          name="birthdate"
          type="date"
          label="Date of Birth"
          value={birthdate}
          setValue={setBirthdate}
          required
        />
        {/* Server expects one field for either referrer or referrerOther so merge them */}
        <input
          type="hidden"
          name="referralSourceType"
          value={referrer === 'Other' && referrerOther ? referrerOther : referrer}
        />
        <FormControl
          name="phoneNumber"
          required
          type="tel"
          label="Phone number"
          value={phone ?? ''}
          setValue={setPhone}
        />
        <FormControl
          name="email"
          type="email"
          label="Email (optional)"
          value={email}
          setValue={setEmail}
        />
        <FormControl
          name="referrer"
          label="How did you hear about Boulder?"
          options={REFERRERS}
          valueFn={(r) => r.value}
          labelFn={(r) => r.label}
          value={referrer}
          setValue={setReferrer}
        />
        {referrer === 'Other' && (
          <FormControl
            name="referrerOther"
            label="Please describe..."
            value={referrerOther}
            setValue={setReferrerOther}
          />
        )}
        <MarketingOptinInput name="communicationOptIn" />

        {/* If a potential participant has selected to pay out of pocket, hide the insurance section */}
        {paymentType !== 'self-pay' && (
          <>
            <h2>{insuranceHeader}</h2>
            <StructuredText data={insurancePreformContent} />
            <div className={classes.insuranceOption}>
              <label>
                <input
                  type="radio"
                  name="insurance"
                  value="yes"
                  onChange={(evt) => setInsurance(evt.target.value)}
                  required
                />
                {insuranceYesOption}
              </label>
              <Collapse isOpened={insurance === 'yes'}>
                <StructuredText data={insuranceYesDetails} className={classes.response} />
                <FileInput files={files} setFiles={setFiles} className={classes.fileInput} />
                <input type="hidden" name="uploadType" value="healthInsurance" />
              </Collapse>
            </div>
            <div className={classes.insuranceOption}>
              <label>
                <input
                  type="radio"
                  name="insurance"
                  value="no"
                  onChange={(evt) => setInsurance(evt.target.value)}
                  required
                />
                {insuranceNoOption}
              </label>
              <Collapse isOpened={insurance === 'no'}>
                <StructuredText data={insuranceNoDetails} className={classes.response} />
              </Collapse>
            </div>
          </>
        )}

        <h2>{scheduleHeader}</h2>
        <StructuredText data={schedulePreformContent} />
        <FormControl
          name="requestedVisitTime"
          label="Call time"
          options={scheduleOptions}
          value={visitTime}
          setValue={setVisitTime}
          required
        />

        <h2>{otherHeader}</h2>
        <FormControl
          name="additional"
          label="Anything else you'd like us to know?"
          value={other}
          setValue={setOther}
          multi
        />
        <SubmitButton className={classes.cta} text={buttonText} submitting={submitting} />
      </form>
    </div>
  );
};

export default BlockScheduleACallForm;
