import React from 'react';
import classnames from 'classnames';
import Div100vh from 'react-div-100vh';
import * as classes from './modal.module.scss';

const Modal = ({ className, contentsClass, onClose, open, children }) => (
  <Div100vh className={classnames(classes.root, className, open && classes.open)}>
    <div className={classnames(classes.contents, contentsClass)}>{children}</div>
    <div className={classes.mask} onClick={onClose} />
  </Div100vh>
);

export default Modal;
