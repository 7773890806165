import React from 'react';
import Modal from './modal';
import * as classes from './marketingOptinModal.module.scss';

const MarketingOptinModal = ({ onClose, open }) => {
  return (
    <Modal contentsClass={classes.marketingModalContents} onClose={onClose} open={open}>
      <strong>Consent to Receive Texts and Emails from Boulder Care, Inc.</strong>
      <p>
        By providing your cell phone number and email address to Boulder Care, Inc. (“Boulder
        Care”), you are agreeing to be contacted by or on behalf of Boulder Care at the email
        address and the telephone number provided, including emails to your email address and text
        (SMS) messages to your cell phone and other wireless devices, and the use of an automatic
        telephone dialing system, artificial voice and prerecorded messages, to providing you with
        marketing and promotional materials relating to Boulder Care’s products and services. You
        may opt-out of receiving text (SMS) messages from Boulder Care or its subsidiaries at any
        time by replying with the word STOP from the mobile device receiving the messages. You need
        not provide this consent in order to purchase any products or services from Boulder Care.
        However, you acknowledge that opting out of receiving text (SMS) messages may impact your
        experience with the service(s) that rely on communications via text (SMS) messaging.
      </p>
    </Modal>
  );
};

export default MarketingOptinModal;
