import React, { useState } from 'react';
import MarketingOptinModal from 'src/components/base/marketingOptinModal';
import * as classes from './marketingOptinInput.module.scss';

const MarketingOptinInput = ({ name }) => {
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);

  const showMarketingModal = (evt) => {
    setOpen(true);
    evt.preventDefault();
  };
  const closeMarketingModal = () => {
    setOpen(false);
  };
  const checkboxToggle = (evt) => {
    setChecked(evt.target.checked);
  };

  return (
    <>
      <div className={classes.marketingOptin}>
        <input type="checkbox" onClick={checkboxToggle} />
        <input type="hidden" name={name} value={checked} />
        <div>
          I agree to receive SMS text messages and emails from Boulder as set forth in {' '}
          <button
            onClick={showMarketingModal}
            className={classes.marketingInputModalButton}
            type="button"
          >
            these terms.
          </button>
        </div>
      </div>
      <MarketingOptinModal open={open} onClose={closeMarketingModal} />
    </>
  );
};

export default MarketingOptinInput;
