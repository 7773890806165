import React, { useEffect } from 'react';
import { graphql, navigate } from 'gatsby';
import ScheduleACallForm from 'src/components/blocks/blockScheduleACallForm.js';
import Layout from 'src/components/layouts/default';
import StructuredText from 'src/components/base/structuredText';
import * as classes from './schedule-a-call.module.scss';


import { useGlobalState } from 'src/components/base/globalState';
export const query = graphql`
  query {
    datoCmsStart {
      scheduleTitle
      scheduleCallNow {
        blocks
        links
        value
      }
      scheduleCallNote {
        blocks
        links
        value
      }
      enrollPreformContent {
        blocks
        links
        value
      }
      enrollDemoHeader
      enrollInsuranceHeader
      enrollInsurancePreformContent {
        blocks
        links
        value
      }
      enrollInsuranceYesOption
      enrollInsuranceYesDetails {
        blocks
        links
        value
      }
      enrollInsuranceNoOption
      enrollInsuranceNoDetails {
        blocks
        links
        value
      }
      enrollScheduleHeader
      enrollSchedulePreformContent {
        blocks
        links
        value
      }
      enrollOtherHeader
      enrollButtonText
      enrollCompleteContent {
        blocks
        links
        value
      }
    }
  }
`;

const ScheduleACallPage = ({ data, location }) => {

  const { state, paymentType, patientReferrer, } = useGlobalState();

  useEffect(() => {
    if (!patientReferrer && (!state || !paymentType)) {
      navigate('/eligibility', { state: { prev: location.pathname, prevLabel: 'Schedule a call' }});
    }
  }, [patientReferrer, state, paymentType, location]);
  return (
  <Layout location={location} pageTitle={data.datoCmsStart.scheduleTitle}>
    <div className={classes.callNow}>
      <div className={classes.contents}>
        <StructuredText data={data.datoCmsStart.scheduleCallNow} />
        <a href="tel:8886080836" className={`${classes.ctaButton} button`}>
          Call: 888-608-0836
        </a>
        <StructuredText data={data.datoCmsStart.scheduleCallNote} />
      </div>
    </div>

    <div className={classes.scheduleCallForm}>
      <ScheduleACallForm
        preformContent={data.datoCmsStart.enrollPreformContent}
        demoHeader={data.datoCmsStart.enrollDemoHeader}
        insuranceHeader={data.datoCmsStart.enrollInsuranceHeader}
        insurancePreformContent={data.datoCmsStart.enrollInsurancePreformContent}
        insuranceYesOption={data.datoCmsStart.enrollInsuranceYesOption}
        insuranceYesDetails={data.datoCmsStart.enrollInsuranceYesDetails}
        insuranceNoOption={data.datoCmsStart.enrollInsuranceNoOption}
        insuranceNoDetails={data.datoCmsStart.enrollInsuranceNoDetails}
        scheduleHeader={data.datoCmsStart.enrollScheduleHeader}
        schedulePreformContent={data.datoCmsStart.enrollSchedulePreformContent}
        otherHeader={data.datoCmsStart.enrollOtherHeader}
        buttonText={data.datoCmsStart.enrollButtonText}
        onComplete={(tokenValues) =>
          navigate('/complete', {
            state: {
              pageTitle: data.datoCmsStart.enrollTitle,
              body: data.datoCmsStart.enrollCompleteContent,
              tokenValues,
            },
          })
        }
      />
    </div>
  </Layout>
);
      }
export default ScheduleACallPage;
