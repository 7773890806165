import React from 'react';
import classnames from 'classnames';
import Dots from 'react-activity/dist/Dots';
import * as classes from './submitButton.module.scss';

import 'react-activity/dist/Dots.css';

const SubmitButton = ({ text = 'Submit', submitting = false, className }) => (
  <div className={classnames(classes.root, className)}>
    <button type="submit" className="button">
      {submitting ? <Dots color="white" /> : <>{text}</>}
    </button>
  </div>
);

export default SubmitButton;
